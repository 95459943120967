import React from 'react'
import * as Yup from 'yup'

import {
  Box,
  FormGroup,
  Icon,
  Paragraph,
  H3,
  P,
  H2,
} from '@allied-solutions/affinity'
import { Check } from '@allied-solutions/affinity-react-icons/dist/16px'

import { useStore } from '../../../hooks'
import { InfoBox, FormLayout, YesNo, Notice } from '../../../components'

const initialValues = {
  CompRadio: '',
  CompDeductible: '',
  CollRadio: '',
  CollDeductible: '',
}

const validationSchema = {
  CompRadio: Yup.string().required(`Please select Yes or No.`),
  CompDeductible: Yup.number().when('CompRadio', {
    is: 'true',
    then: Yup.number().required(`Please enter a dollar amount.`),
    otherwise: Yup.number(),
  }),
  CollRadio: Yup.string().required(`Please select Yes or No.`),
  CollDeductible: Yup.number().when('CollRadio', {
    is: 'true',
    then: Yup.number().required(`Please enter a dollar amount.`),
    otherwise: Yup.number(),
  }),
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const ComprehensiveFields = ({ values }) => {
  console.log(values)
  return (
    <>
      <FormGroup id="CompRadio" mt={9} required>
        <YesNo
          label="Does your policy include comprehensive coverage?"
          tooltipContent="Comprehensive coverage applies to accidents not involving a collision with another vehicle or object (i.e. damage caused by fire, animals, falling objects, theft)."
          checked={values.CompRadio}
        />
      </FormGroup>

      {values.CompRadio === 'true' ? (
        <FormGroup id="CompDeductible" mt={9} required>
          <FormGroup.Label visible>Enter the deductible amount</FormGroup.Label>
          <FormGroup.InputGroupMoney />
          <FormGroup.Caption />
        </FormGroup>
      ) : null}

      {values.CompRadio === 'true' && values.CompDeductible > 2500 ? (
        <Notice mt={10}>
          <P>Before moving on,</P> please double check the comprehensive
          deductible entered above.{' '}
          <P>{formatter.format(values.CompDeductible)}</P> could be a higher
          value than you intended to enter.
        </Notice>
      ) : null}
    </>
  )
}

const CollisionFields = ({ values }) => (
  <>
    <FormGroup id="CollRadio" mt={9} required>
      <YesNo
        label="Does your policy include collision coverage?"
        tooltipContent="Collision coverage protects your own car when you hit another car, object, or road surface hazard. It does not cover damage to someone else’s vehicle; that is covered by liability insurance."
        checked={values.CollRadio}
      />
    </FormGroup>

    {values.CollRadio === 'true' ? (
      <FormGroup id="CollDeductible" mt={9} required>
        <FormGroup.Label visible>Enter the deductible amount</FormGroup.Label>
        <FormGroup.InputGroupMoney />
        <FormGroup.Caption />
      </FormGroup>
    ) : null}

    {values.CollRadio === 'true' && values.CollDeductible > 2500 ? (
      <Notice mt={10}>
        <P>Before moving on,</P> please double check the collision deductible
        entered above. <P>{formatter.format(values.CollDeductible)}</P> could be
        a higher value than you intended to enter.
      </Notice>
    ) : null}
  </>
)

const DeductibleInfoPage = ({ location }) => {
  const { state } = useStore()

  return (
    <FormLayout
      leftSide={{
        title: 'Enter your deductible information.',
        body: (
          <Paragraph
            typeStyle="bodyMedium"
            textAlign="left"
            color="secondary"
            mb={14}
          >
            You can find your deductible amount(s) listed on your insurance
            policy. It’s the amount you have to pay out-of-pocket before your
            insurance provider pays any expenses.
          </Paragraph>
        ),
      }}
      rightSide={{
        replace: props => (
          <>
            <InfoBox>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <H2
                  className="titleSmall"
                  typeStyle="titleSmall"
                  fontWeight={900}
                >
                  1. Comprehensive Coverage
                </H2>

                {props.values.CompDeductible > 0 ||
                props.values.CompRadio === 'false' ? (
                  <Box
                    width="10"
                    height="10"
                    borderRadius="full"
                    bg="blue200"
                    display={['none', 'none', 'flex']}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Icon src={Check} color="blue800" />
                  </Box>
                ) : (
                  <Box
                    width="10"
                    height="10"
                    display={['none', 'none', 'block']}
                    borderRadius="full"
                    bg="gray80"
                  />
                )}
              </Box>
              <ComprehensiveFields values={props.values} />
            </InfoBox>

            <InfoBox>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <H2
                  className="titleSmall"
                  typeStyle="titleSmall"
                  fontWeight={900}
                >
                  2. Collision Coverage
                </H2>
                {props.values.CollDeductible > 0 ||
                props.values.CollRadio === 'false' ? (
                  <Box
                    width="10"
                    height="10"
                    borderRadius="full"
                    bg="blue200"
                    display={['none', 'none', 'flex']}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Icon src={Check} color="blue800" />
                  </Box>
                ) : (
                  <Box
                    width="10"
                    height="10"
                    display={['none', 'none', 'block']}
                    borderRadius="full"
                    bg="gray80"
                  />
                )}
              </Box>
              <CollisionFields values={props.values} />
            </InfoBox>
          </>
        ),
      }}
      {...{ location, validationSchema }}
      mergeInitialValues={false}
      initialValues={{
        ...initialValues,
        ...state.borrower,
        CompRadio: state?.borrower?.CompDeductible ? `true` : `false`,
        CollRadio: state?.borrower?.CollDeductible ? `true` : `false`,
        ...state.form,
      }}
    />
  )
}

export default DeductibleInfoPage
export { initialValues, validationSchema, ComprehensiveFields, CollisionFields }
